:host {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0.05rem solid var(--theme-6);
  border-radius: 0.2rem;
  background-color: var(--theme-6);
  padding: 0.25rem 0.75rem;
  white-space: nowrap;
  font-weight: 700;
  overflow: hidden; 
  text-overflow: ellipsis;
  margin: 0;
}

:host ::slotted(*[slot="icon"]),
:host ::slotted(*[slot="avatar"]) {
  margin-right: .5rem;
  margin-left: -.25rem;
}

:host ::slotted([slot="action"]) {
  margin-left: .5rem;
  margin-right: -.25rem;
}

:host .tag {
  color: var(--theme-0);
  font-size: 1rem;
  margin: 0;
}

:host([size="tiny"]) .tag {
  font-size: 0.75rem;
}

:host([size="small"]) .tag {
  font-size: 0.875rem;
}

:host([size="large"]) .tag {
  font-size: 1.25rem;
}

:host([pill]) {
  border-radius: 500rem;
}

:host([dark]) {
  background-color: var(--theme-9);
}

:host([dark]) .tag {
  color: var(--theme-1);
}
